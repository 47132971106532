var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('auth-layout',[_c('div',{staticClass:"login ma-0 pa-4 pb-0 bg-color--blue color--white"},[(_vm.actionShown === 'signup')?_c('form',{ref:"loginForm",staticClass:"w-100",on:{"submit":function($event){$event.preventDefault();return _vm.signup.apply(null, arguments)}}},[_c('div',{staticClass:"lg:ml-20"},[_c('div',[_c('h1',{staticClass:"text-4xl mb-5 font-thin text-green003"},[_vm._v(" Sign up ")]),_c('p',{staticClass:"font-thin mb-33 text-green002"},[_vm._v(" Your first tasq, sign up. Fill in the information below to get started. ")]),_c('div',{staticClass:"relative flex w-full flex-wrap items-stretch gap-10 mb-2 mt-12"},[_c('div',{staticClass:"float-left flex-1"},[_c('t-input',{staticClass:"px-3 py-3 placeholder-green014 text-black relative text-sm w-full pl-0 appearance-none rounded-br-none rounded-bl-none shadow-none border-t-0 border-l-0 border-r-0 border-b-1 bg-opacity-0 outline-none focus:outline-none shadow-none",class:{
                    'textfield-default--black--filled': _vm.email,
                    'textfield-default--black--error': _vm.errorMessage
                  },attrs:{"name":"firstName","type":"firstName","placeholder":"First name","rules":[
                    v => !!v || 'Email must not be blank',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
                  ]},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),(_vm.firstNameErrorMessage)?_c('p',{staticStyle:{"font-size":"14px","font-weight":"400","color":"red"}},[_vm._v(" "+_vm._s(_vm.firstNameErrorMessage)+" ")]):_vm._e()],1),_c('div',{staticClass:"float-right flex-1"},[_c('t-input',{staticClass:"px-3 py-3 focus:outline-white placeholder-green014 text-black relative text-sm w-full pl-0 appearance-none rounded-br-none rounded-bl-none shadow-none border-t-0 border-l-0 border-r-0 border-b-1 bg-opacity-0",class:{
                    'textfield-default--black--filled': _vm.email,
                    'textfield-default--black--error': _vm.errorMessage
                  },attrs:{"name":"lastName","type":"lastName","placeholder":"Last name","rules":[
                    v => !!v || 'Email must not be blank',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
                  ]},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),(_vm.lastNameErrorMessage)?_c('p',{staticStyle:{"font-size":"14px","font-weight":"400","color":"red"}},[_vm._v(" "+_vm._s(_vm.lastNameErrorMessage)+" ")]):_vm._e()],1)]),_c('t-select',{staticClass:"relative bg-white h-10 text-green014 border-gray-300 border border-t-0 border-l-0 border-r-0 border-b-1 w-full mt-10 h-12 px-0",attrs:{"placeholder":"Role","options":['Engineer', 'Automation', 'Field Operator', 'Optimizer', 'Wireline', 'Workover', 'Foreman', 'Measurement Tech'],"variant":"demo"},model:{value:(_vm.roleSelection),callback:function ($$v) {_vm.roleSelection=$$v},expression:"roleSelection"}}),(_vm.roleErrorMessage)?_c('p',{staticStyle:{"font-size":"14px","font-weight":"400","color":"red"}},[_vm._v(" "+_vm._s(_vm.roleErrorMessage)+" ")]):_vm._e(),_c('div',{staticClass:"relative flex w-full flex-wrap items-stretch gap-10 mb-2 mt-12"},[_c('div',{staticClass:"float-left flex-1"},[_c('span',{staticClass:"z-10 h-full leading-snug font-normal absolute text-center text-black absolute bg-transparent rounded text-base items-center justify-center w-5 pl-0 py-3 pt-4"},[_c('img',{attrs:{"src":require("../../assets/email_icon_light_green.png")}})]),_c('t-input',{staticClass:"px-3 py-3 focus:outline-white placeholder-green014 text-black relative text-sm w-full pl-8 appearance-none rounded-br-none rounded-bl-none shadow-none border-t-0 border-l-0 border-r-0 border-b-1 bg-opacity-0",class:{
                    'textfield-default--black--filled': _vm.email,
                    'textfield-default--black--error': _vm.errorMessage
                  },attrs:{"name":"email","type":"email","placeholder":"your@email.com","rules":[
                    v => !!v || 'Email must not be blank',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
                  ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),(_vm.emailErrorMessage)?_c('p',{staticStyle:{"font-size":"14px","font-weight":"400","color":"red"}},[_vm._v(" "+_vm._s(_vm.emailErrorMessage)+" ")]):_vm._e()],1),_c('div',{staticClass:"float-right flex-1"},[_c('span',{staticClass:"z-10 h-full leading-snug font-normal absolute text-center text-black absolute bg-transparent rounded text-base items-center justify-center w-5 pl-0 py-3 pt-3"},[_c('img',{attrs:{"src":require("../../assets/phone_green_icon.png")}})]),_c('t-input',{staticClass:"px-3 py-3 focus:outline-white placeholder-green014 text-black relative text-sm w-full pl-8 appearance-none rounded-br-none rounded-bl-none shadow-none border-t-0 border-l-0 border-r-0 border-b-1 bg-opacity-0",class:{
                    'textfield-default--black--filled': _vm.email,
                    'textfield-default--black--error': _vm.errorMessage
                  },attrs:{"name":"phoneNumber","type":"phoneNumber","placeholder":"+1(555) 555-5555","rules":[
                    v => !!v || 'Phone number must not be blank',
                    v => /.+@.+\..+/.test(v) || 'Phone number must be valid'
                  ]},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),(_vm.phoneNumberErrorMessage)?_c('p',{staticStyle:{"font-size":"14px","font-weight":"400","color":"red"}},[_vm._v(" "+_vm._s(_vm.phoneNumberErrorMessage)+" ")]):_vm._e()],1)])],1),_c('div',{staticClass:"login__bottom-col"},[_c('router-link',{staticClass:"text-green002 text-green014 float-left px-0 py-3 mt-4 font-medium text-sm cursor-pointer",attrs:{"to":"/login"}},[_vm._v(" Log in ")]),_c('div',{staticClass:"hover:bg-green001 button-default text-white float-right rounded-full bg-green019 px-14 py-3 mt-4 font-semibold text-sm shadow-sendCodeButtonShadow cursor-pointer",attrs:{"id":"send_code_btn","color":"#000750","dark":"","disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.signup}},[_vm._v(" NEXT ")])],1)])]):_vm._e(),(_vm.actionShown === 'verify')?_c('form',{staticClass:"w-100",on:{"submit":function($event){$event.preventDefault();return _vm.verify.apply(null, arguments)}}},[_c('div',[_c('div',[_c('h1',{staticClass:"text-4xl mb-4 font-thin text-green003"},[_vm._v(" Verification code sent. ")]),_c('p',{staticClass:"font-thin mb-33 text-green002"},[_vm._v(" Check your text messages and enter the six-digit code to complete your sign up. ")]),_c('div',{staticClass:"login__code-wrapper mt-10"},_vm._l((_vm.CODE_LENGTH),function(i){return _c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code[i - 1]),expression:"code[i - 1]"}],key:i,ref:`codeInput-${i - 1}`,refInFor:true,staticClass:"textfield-code m-1 text-black bg-gray-100 h-14 w-9 text-2xl text-center rounded",class:{
                  'textfield-code--filled': _vm.code[i - 1],
                  'textfield-code--error': _vm.errorMessage
                },attrs:{"color":"#000","type":"text","maxLength":"1","size":"1","min":"0","max":"9","pattern":"[0-9]*"},domProps:{"value":(_vm.code[i - 1])},on:{"keyup":function($event){return _vm.changeInputFocus(i - 1)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.code, i - 1, $event.target.value)}}})}),0),(_vm.errorMessage)?_c('p',{staticStyle:{"font-size":"14px","font-weight":"400","color":"red"}}):_vm._e()]),_c('div',{staticClass:"login__bottom-col"},[_c('div',{staticClass:"hover:bg-green001 button-default text-white float-left rounded-full bg-green019 px-14 py-3 mt-4 font-semibold text-sm shadow-sendCodeButtonShadow cursor-pointer",attrs:{"id":"verify_code_btn","color":"#000750","dark":"","disabled":_vm.verifyBtnDisabled || _vm.loading,"loading":_vm.loading},on:{"click":_vm.verify}},[_vm._v(" Verify ")]),_c('p',{staticClass:"font--14"},[_vm._v(" Text didn't arrive? "),_c('br'),_c('a',{staticClass:"font-bold text-green014 float-right md:float-left md:ml-32 text-underline cursor-pointer",on:{"click":function($event){return _vm.signup(true)}}},[_vm._v(" Send another code ")])])])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }